// @ts-strict-ignore
import { qpGetHeaderValue } from '@library/functions/qp-get-header-value/qp-get-header-value';
import { EQpImageThumbnailSize } from '@library/models/qp-image.models';
import { SERVER_API_URL } from '@one/app/app.constants';
import { EInspectionDocumentType } from '@one/app/pages/isp/pages/inspection/pages/id/shared/components/instruction-documents/instruction-documents.models';
import { InspectionId } from '@one/app/pages/isp/shared/models/isp-inspection.models';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class InstructionDocumentsService {
  public constructor(private readonly httpClient: HttpClient) {}

  public getInstructionDocumentByInspection(
    id: string | number,
    documentId: string,
    isThumbnail = true,
    documentType: EInspectionDocumentType = EInspectionDocumentType.GLOBAL
  ): Observable<{ id: string; type: string; data: ArrayBuffer }> {
    return this.httpClient
      .get(`${SERVER_API_URL}/api/${documentType}/${id}/documents/${documentId}`, {
        responseType: 'arraybuffer',
        observe: 'response',
        params: isThumbnail ? { thumbnail: EQpImageThumbnailSize.S256 } : null,
      })
      .pipe(
        map((response): { id: string; type: string; data: ArrayBuffer } => {
          return {
            id: documentId,
            type: qpGetHeaderValue(response.headers, 'content-type'),
            data: response.body,
          };
        })
      );
  }

  public getInstructionDocumentMetadataByInspection$(
    id: InspectionId,
    documentId: string,
    documentType: EInspectionDocumentType = EInspectionDocumentType.GLOBAL
  ): Observable<{ name: string; type: string; size: string }> {
    return this.httpClient.get(`${SERVER_API_URL}/api/${documentType}/${id}/documents/${documentId}/metadata`, { observe: 'body' }).pipe(
      map((body): { name: string; type: string; size: string } => {
        return {
          name: body['Filename'] ?? body['filename'],
          type: body['Content-Type'] ?? body['content-type'],
          size: body['Content-Length'] ?? body['content-length'],
        };
      })
    );
  }
}
